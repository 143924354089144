<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1 p-relative">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          รายการตะกร้า
        </h3>
      </div>

      <!-- <div>
        <div class="mt-1">
          <p class="mb-0  text-primary">
            งวดวันที่ 16 ส.ค 2566
          </p>
        </div>
      </div> -->

      <!-- Desktop and tablet only -->
      <div class="d-none d-md-block">
        <div
          v-if="MyCart.length > 0"
          class="card-lot-set my-1"
        >
          <p class="txt-lot-set">
            รายการลอตเตอรี่ชุด
          </p>

          <table class="w-100">
            <tbody>
              <tr
                v-for="(item, index) in MyCart"
                :key="index"
                class="list-item-lot"
              >
                <td style="width: 10%">
                  <button @click="deleteItem(item._id)">
                    <i class="fal fa-trash-alt" />
                  </button>
                </td>

                <td style="width: 55%">
                  <span class="text-primary mb-0 mr-1 box-lot-cart">
                    {{ item.lottonumber }}
                  </span>
                </td>

                <td style="width: 7%">
                  <p class="-set mb-0">
                    {{ item.orderby[item.orderby.findIndex(x => x.username === userData.username)].count }} ใบ
                  </p>
                </td>

                <td style="width: 28%">
                  <!-- <p class="-price mb-0">
                    35,000 <small>₩</small>
                  </p> -->
                  <p
                    v-if="item.type == 0"
                    class="-price mb-0"
                  >
                    {{ price ? Commas(item.orderby[item.orderby.findIndex(x => x.username === userData.username)].count * price.sellprice.toFixed(2)) : 0.0 }}
                    <small class="txt-warn">₩</small>
                  </p>
                  <p
                    v-else
                    class="-price mb-0"
                  >
                    {{ price ? Commas(item.orderby[item.orderby.findIndex(x => x.username === userData.username)].count * price.luckynum_price.toFixed(2)) : 0.0 }}
                    <small class="txt-warn">₩</small>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- box2b -->
        <div
          v-if="box2b.length > 0"
          class="card-lot-set my-1"
        >
          <p class="txt-lot-set">
            รายการกล่องสุ่มเลขท้าย 2 ตัว
          </p>

          <table class="w-100">
            <tbody
              v-for="(item, index) in box2b"
              :key="index"
            >
              <tr
                class="list-item-lot"
                style="position: relative"
              >
                <td style="width: 10%">
                  <button @click="deleteItemBox(item._id)">
                    <i class="fal fa-trash-alt" />
                  </button>
                </td>

                <td style="width: 55%">
                  <span class="text-primary mb-0 mr-1 box-lot-cart">
                    ??????
                  </span>
                </td>

                <td style="width: 7%">
                  <p class="-set mb-0">
                    {{ item.count }}
                  </p>
                </td>

                <td style="width: 28%">
                  <p class="-price mb-0">
                    {{ Commas(item.price_discount) }}
                    <small class="txt-warn">₩</small>
                  </p>
                </td>

                <span class="wdp-ribbon-six">
                  <span class="wdp-ribbon-text">-{{ item.percent }}%</span>
                </span>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- box6 -->
        <div
          v-if="box6.length > 0"
          class="card-lot-set my-1"
        >
          <p class="txt-lot-set">
            รายการกล่องสุ่มลอตเตอรี่ชุด
          </p>

          <table class="w-100">
            <tbody
              v-for="(item, index) in box6"
              :key="index"
            >
              <tr
                class="list-item-lot"
                style="position: relative"
              >
                <td style="width: 10%">
                  <button @click="deleteItemBox(item._id)">
                    <i class="fal fa-trash-alt" />
                  </button>
                </td>

                <td style="width: 55%">
                  <span class="text-primary mb-0 mr-1 box-lot-cart">
                    ??????
                  </span>
                </td>

                <td style="width: 5%">
                  <p class="-set mb-0">
                    {{ item.count }}
                  </p>
                </td>

                <td style="width: 30%">
                  <p class="-price mb-0">
                    {{ Commas(item.price_discount) }}
                    <small class="txt-warn">₩</small>
                  </p>
                </td>

                <span class="wdp-ribbon wdp-ribbon-six">
                  <span class="wdp-ribbon-inner-wrap">
                    <span class="wdp-ribbon-border" />
                    <span class="wdp-ribbon-text">-{{ item.percent }}%</span>
                  </span>
                </span>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Mobile only -->
      <div class="d-block d-md-none">
        <div
          v-if="MyCart.length > 0"
          class="card-lot-set my-1"
        >
          <p class="txt-lot-set">
            รายการลอตเตอรี่ชุด
          </p>

          <table class="w-100">
            <tbody>
              <tr
                v-for="(item, index) in MyCart"
                :key="index"
                class="list-item-lot"
              >
                <td style="width: 10%">
                  <button @click="deleteItem(item._id)">
                    <i class="fal fa-trash-alt" />
                  </button>
                </td>

                <td style="width: 40%">
                  <span class="text-primary mb-0 mr-1 box-lot-cart">
                    {{ item.lottonumber }}
                  </span>
                </td>

                <td style="width: 7%">
                  <p class="-set mb-0">
                    1
                  </p>
                </td>

                <td style="width: 38%">
                  <!-- <p class="-price mb-0">
                    35,000 <small>₩</small>
                  </p> -->
                  <p
                    v-if="item.type == 0"
                    class="-price mb-0"
                  >
                    {{ price ? Commas(price.sellprice.toFixed(2)) : 0.0 }}
                    <small class="txt-warn">₩</small>
                  </p>
                  <p
                    v-else
                    class="-price mb-0"
                  >
                    {{ price ? Commas(price.luckynum_price.toFixed(2)) : 0.0 }}
                    <small class="txt-warn">₩</small>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="box2b.length > 0"
          class="card-lot-set my-1"
        >
          <p class="txt-lot-set">
            รายการกล่องสุ่มเลขท้าย 2 ตัว
          </p>

          <table class="w-100">
            <tbody
              v-for="(item, index) in box2b"
              :key="index"
            >
              <tr
                class="list-item-lot"
                style="position: relative"
              >
                <td style="width: 10%">
                  <button @click="deleteItemBox(item._id)">
                    <i class="fal fa-trash-alt" />
                  </button>
                </td>

                <td style="width: 40%">
                  <span class="text-primary mb-0 mr-1 box-lot-cart">
                    ??????
                  </span>
                </td>

                <td style="width: 7%">
                  <p class="-set mb-0">
                    {{ item.count }}
                  </p>
                </td>

                <td style="width: 38%">
                  <p class="-price mb-0">
                    {{ Commas(item.price_discount) }}
                    <small class="txt-warn">₩</small>
                  </p>
                </td>

                <span class="wdp-ribbon wdp-ribbon-six">
                  <span class="wdp-ribbon-inner-wrap">
                    <span class="wdp-ribbon-border" />
                    <span class="wdp-ribbon-text">-{{ item.percent }}%</span>
                  </span>
                </span>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="box6.length > 0"
          class="card-lot-set my-1"
        >
          <p class="txt-lot-set">
            รายการกล่องสุ่มลอตเตอรี่ชุด
          </p>

          <table class="w-100">
            <tbody
              v-for="(item, index) in box6"
              :key="index"
            >
              <tr
                class="list-item-lot"
                style="position: relative"
              >
                <td style="width: 10%">
                  <button @click="deleteItemBox(item._id)">
                    <i class="fal fa-trash-alt" />
                  </button>
                </td>

                <td style="width: 40%">
                  <span class="text-primary mb-0 mr-1 box-lot-cart">
                    ??????
                  </span>
                </td>

                <td style="width: 7%">
                  <p class="-set mb-0">
                    {{ item.count }}
                  </p>
                </td>

                <td style="width: 38%">
                  <p class="-price mb-0">
                    {{ Commas(item.price_discount) }}
                    <small class="txt-warn">₩</small>
                  </p>
                </td>

                <span class="wdp-ribbon wdp-ribbon-six">
                  <span class="wdp-ribbon-inner-wrap">
                    <span class="wdp-ribbon-border" />
                    <span class="wdp-ribbon-text">-{{ item.percent }}%</span>
                  </span>
                </span>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        v-if="MyCart.length === 0 && box2b.length === 0 && box6.length === 0"
        class="text-center mt-2 mb-4"
      >
        <img
          src="/icon/empty.png"
          alt="friend"
          height="75"
        >

        <p class="mb-0 text">
          ยังไม่มีลอตเตอรี่
        </p>
      </div>

      <!-- <div
        v-for="(item, index) in MyCart"
        :key="index"
        class="card-list pxp mt-1 border-0 mb-2"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <h3 class="text-white  mb-0 bg-lot mr-1 box-lot-cart">
              {{ item.lottonumber }}
            </h3>

            <div class="text-center">
              <small class="mb-0 text-white">
                จำนวนชุด
              </small>

              <p class="mb-0 text-white ">
                <span class="font-medium-4">{{ item.count }}</span>
              </p>
            </div>
          </div>

          <div class="ml-auto">
            <small class="mb-0 text-white">
              ค่าสลาก
            </small>

            <p
              v-if="item.type == 0"
              class="mb-0 text-white "
            >
              <span class="font-medium-4">{{ price ? Commas(price.sellprice.toFixed(2)) : 0.00 }}</span> ₩
            </p>
            <p
              v-else
              class="mb-0 text-white "
            >
              <span class="font-medium-4">{{ price ? Commas(price.luckynum_price.toFixed(2)): 0.00 }}</span> ₩
            </p>
          </div>
        </div>

        <button
          class="btn-delete-x"
          @click="deleteItem(item._id)"
        >
          <i class="fas fa-trash-alt cursor-pointer" />
        </button>
      </div> -->

      <!-- footer here balance -->
      <div
        v-if="MyCart.length || box6.length || box2b.length"
        class="ft-balance"
      >
        <div class="text-center">
          <p class="mb-0">
            กรุณาชำระเงินภายใน
            <span
              class="text-warning"
            >00 : {{ CountTimeShow ? CountTimeShow : "00 : 00" }}</span>
            นาที
          </p>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div class="rf text-center">
            <p class="mb-0 text-primary">
              จำนวนชุด
            </p>

            <p class="mb-0 font-large-1 text-warning">
              {{ MyCart.length + box6.length + box2b.length }}
            </p>
          </div>

          <div class="rf">
            <p class="mb-0 text-primary text-right">
              ยอดรวมทั้งหมด
            </p>

            <p class="mb-0 font-large-1 text-warning text-center">
              {{ Commas(sumprice ? sumprice.toFixed(2) : 0.0) }}
              <small class="txt-warn">₩</small>
            </p>
          </div>
        </div>

        <div class="text-center">
          <button
            :disabled="
              (MyCart.length === 0 &&
                box6.length === 0 &&
                box2b.length === 0) ||
                Loadmore == true
            "
            class="btn mt-50 w-50"
            @click="$refs['modal-confirm-buy'].show()"
          >
            ชำระเงิน
          </button>
        </div>
      </div>

      <b-modal
        ref="modal-confirm-del"
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <h3 class="text-primary">
            คุณต้องลบสลากใบนี้หรือไม่ ?
          </h3>
        </div>

        <div class="text-center">
          <button
            class="btn-confirm"
            @click="confirmRemove(id)"
          >
            ลบ
          </button>

          <button
            class="btn-del"
            @click="$refs['modal-confirm-del'].hide()"
          >
            ยกเลิก
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="modal-confirm-delbox"
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <h3 class="text-primary">
            คุณต้องลบกล่องสุ่มนี้หรือไม่ ?
          </h3>
        </div>

        <div class="text-center">
          <button
            class="btn-confirm"
            @click="confirmRemoveBox(id)"
          >
            ลบ
          </button>

          <button
            class="btn-del"
            @click="$refs['modal-confirm-delbox'].hide()"
          >
            ยกเลิก
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="modal-confirm-buy"
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <h3 class="text-primary">
            ยืนยันการซื้อสลาก
          </h3>
        </div>

        <div class="text-center">
          <button
            class="btn-confirm"
            @click="confirmSubmit"
          >
            ยืนยัน
          </button>

          <button
            class="btn-del"
            @click="$refs['modal-confirm-buy'].hide()"
          >
            ยกเลิก
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="modal-confirm-buy"
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <h3 class="text-primary">
            ยืนยันการซื้อสลาก
          </h3>
        </div>

        <div class="d-flex align-items-center justify-content-center my-2">
          <p class="mb-0 text-primary">
            ได้รับ
          </p>

          <input
            v-model="mypoint"
            disabled
            type="number"
            class="point-txt mx-1"
          >

          <p class="mb-0 text-primary">
            Point
          </p>
        </div>

        <div class="text-center">
          <button
            class="btn-confirm"
            @click="confirmSubmit"
          >
            ยืนยัน
          </button>

          <button
            class="btn-del"
            @click="$refs['modal-confirm-buy'].hide()"
          >
            ยกเลิก
          </button>
        </div>
      </b-modal>
      <!-- <ThisFooter
        :added-ids="MyCarts"
        :countdown-time="countdownTime"
      /> -->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
// import ThisFooter from './components/ThisFooter.vue'
// import ThisHeader from './components/ThisHeader.vue'
import zlib from 'zlib'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    // ThisFooter,
  },
  data() {
    return {
      addedIds: [],
      Loadmore: false,
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: 0,
      price: null,
      MyCart: JSON.parse(localStorage.getItem('MyCart')),
      sellprice: JSON.parse(localStorage.getItem('sellprice')),
      luckynum_price: JSON.parse(localStorage.getItem('luckynum_price')),
      balance_withdraw: 0,
      balance_datecheck: '',
      CountTimeShow: JSON.parse(localStorage.getItem('CountTimeShow'))
        ? JSON.parse(localStorage.getItem('CountTimeShow'))
        : '00 : 00',
      sumprice: JSON.parse(localStorage.getItem('sumprice')),
      intervalID: null,
      normal: 0,
      lucky: 0,
      lotto_data: [],
      box2b: [],
      box6: [],
      getpoints: 0,
      mypoint: 0,
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
    this.intervalClearMyCart = setInterval(() => {
      const time = moment().subtract(30, 'minutes')
      if (this.MyCart.length > 0 && moment(this.MyCart[0].updated_at) < time) {
        // eslint-disable-next-line no-underscore-dangle
        this.RemovelottoMycart(this.MyCart[0]._id)
        // ลบข้อมูลใน ใน this.MyCart array 0
        this.MyCart.splice(0, 1)
        // set ค่าใหม่ให้ localStorage
        localStorage.setItem('MyCart', JSON.stringify(this.MyCart))
      } else if (
        this.box6.length > 0
        && moment(this.box6[0].updated_at) < time
      ) {
        // eslint-disable-next-line no-underscore-dangle
        this.RemoveBoxMycart(this.box6[0]._id)
        // ลบข้อมูลใน ใน this.MyCart array 0
        this.box6.splice(0, 1)
        // set ค่าใหม่ให้ localStorage
        localStorage.setItem('box6', JSON.stringify(this.box6))
      } else if (
        this.box2b.length > 0
        && moment(this.box2b[0].updated_at) < time
      ) {
        // eslint-disable-next-line no-underscore-dangle
        this.RemoveBoxMycart(this.box2b[0]._id)
        // ลบข้อมูลใน ใน this.MyCart array 0
        this.box2b.splice(0, 1)
        // set ค่าใหม่ให้ localStorage
        localStorage.setItem('box2b', JSON.stringify(this.box2b))
      }
    }, 1000)
  },
  // clearInterval
  beforeDestroy() {
    clearInterval(this.intervalID)
    clearInterval(this.intervalClearMyCart)
  },
  async mounted() {
    if (this.userData) {
      await this.GetPrice()
      this.GetMycart()
      this.getBalance()
    }
    if (localStorage.getItem('box6')) {
      this.box6 = JSON.parse(localStorage.getItem('box6'))
    }
    if (localStorage.getItem('box2b')) {
      this.box2b = JSON.parse(localStorage.getItem('box2b'))
    }
    // this.CalTime()
  },
  methods: {
    deleteItem(id) {
      this.$refs['modal-confirm-del'].show()
      this.id = id
    },
    deleteItemBox(id) {
      this.$refs['modal-confirm-delbox'].show()
      this.id = id
    },
    getBalance() {
      this.$http.get('balance/getbalance').then(ress => {
        // console.log(ress.data)
        this.balance = ress.data
        this.balance_datecheck = moment()
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss')
        localStorage.setItem('balance', JSON.stringify(ress.data.balance))
      })
    },
    GetPrice() {
      this.$http.get('lotto-suvarn/GetPrice').then(ress => {
        // console.log(ress.data)
        this.price = ress.data
        this.sellprice = ress.data.sellprice
        this.luckynum_price = ress.data.luckynum_price
        this.getpoints = ress.data.points
      })
    },
    GetMycart() {
      this.Loadmore = true
      // const obj = {
      //   perpage: 100,
      //   page: 1,
      // }
      this.$http.post('lotto-suvarn/MyCart').then(async ress => {
        this.Loadmore = false
        // console.log(ress.data)
        this.MyCart = ress.data.MyCart
        localStorage.setItem('MyCart', JSON.stringify(ress.data.MyCart))
        this.box6 = ress.data.box6
        localStorage.setItem('box6', JSON.stringify(ress.data.box6))
        this.box2b = ress.data.box2b
        localStorage.setItem('box2b', JSON.stringify(ress.data.box2b))

        this.CalPrice()
        if (
          this.MyCart.length > 0
          || this.box6.length > 0
          || this.box2b.length > 0
        ) {
          this.CalTime()
        }
      })
    },
    async confirmSubmit() {
      await this.CalSubmit()
      this.Buylotto()
      this.$refs['modal-confirm-buy'].hide()
    },
    Buylotto() {
      this.Loadmore = true
      const obj = {
        lotto_data: this.lotto_data,
        normal: this.normal,
        lucky: this.lucky,
        box: this.box6.concat(this.box2b),
      }
      // console.log('order')
      // console.log(obj)
      const jsonStream = JSON.stringify(obj)
      zlib.gzip(jsonStream, (err, compressedData) => {
        if (!err) {
          this.$http
            .post('lotto-suvarn/BuyLotto', compressedData, {
              headers: {
                'Content-Type': 'application/json',
                'Content-Encoding': 'gzip',
              },
            })
            .then(response => {
              this.Loadmore = false
              this.box6 = []
              localStorage.setItem('box6', JSON.stringify([]))
              this.box2b = []
              localStorage.setItem('box2b', JSON.stringify([]))
              this.CountTimeShow = '00 : 00'
              localStorage.removeItem('CountTimeShow')
              localStorage.removeItem('sumprice')

              clearInterval(this.intervalID)
              localStorage.setItem('MyCart', JSON.stringify([]))

              this.sumprice = 0
              this.mypoint = 0
              this.GetMycart()
              this.normal = 0
              this.lucky = 0
              this.lotto_data = []

              this.Success(response.data.message)
              // ให้ไปหน้า lottery
              // window.location.href = '/lottery'
              // this.$router.push({ name: 'lottery' })
            })
            .catch(error => {
              this.Loadmore = false
              this.SwalError(error.response.data.message)
            })
        } else {
          this.Loadmore = false
          this.SwalError('เกิดข้อผิดพลาด')
        }
      })
    },
    CalSubmit() {
      this.normal = 0
      this.lucky = 0
      this.lotto_data = []
      // eslint-disable-next-line no-unused-vars
      this.MyCart.forEach(item => {
        // console.log(item)
        const index = item.orderby.findIndex(x => x.username === this.userData.username)
        // eslint-disable-next-line no-underscore-dangle
        this.lotto_data.push({ id: item._id, count: item.orderby[index].count, orderId: item.orderby[index]._id })
        if (item.type === 0) {
          this.normal += 1
        } else {
          this.lucky += 1
        }
      })
    },
    confirmRemove(id) {
      this.RemovelottoMycart(id)
      this.$refs['modal-confirm-del'].hide()
    },
    confirmRemoveBox(id) {
      this.RemoveBoxMycart(id)
      this.$refs['modal-confirm-delbox'].hide()
    },
    RemovelottoMycart(id) {
      this.Loadmore = true
      const obj = {
        id,
      }
      this.$http
        .post('lotto-suvarn/RemovelottoMycart', obj)
        .then(async ress => {
          this.Loadmore = false
          this.MyCart = ress.data.MyCart
          // this.box6 = ress.data.box6
          // this.box2b = ress.data.box2b
          localStorage.setItem('MyCart', JSON.stringify(ress.data.MyCart))
          // localStorage.setItem('box6', JSON.stringify(ress.data.box6))
          // localStorage.setItem('box2b', JSON.stringify(ress.data.box2b))
          this.CalPrice(1)
          if (
            this.MyCart.length > 0
            || this.box6.length > 0
            || this.box2b.length > 0
          ) {
            this.CalTime()
          } else {
            clearInterval(this.intervalID)
            this.CountTimeShow = '00 : 00'
            localStorage.setItem(
              'CountTimeShow',
              JSON.stringify(this.CountTimeShow),
            )
          }
        })
    },
    RemoveBoxMycart(id) {
      this.Loadmore = true
      const obj = {
        id,
      }
      this.$http
        .put('lotto-suvarn/RemoveBoxMycart', obj)
        // eslint-disable-next-line no-unused-vars
        .then(async ress => {
          this.Loadmore = false
          this.box6 = ress.data.box6
          this.box2b = ress.data.box2b
          localStorage.setItem('box6', JSON.stringify(ress.data.box6))
          localStorage.setItem('box2b', JSON.stringify(ress.data.box2b))
          if (localStorage.getItem('boxall')) {
            const boxall = localStorage.getItem('boxall')
            localStorage.setItem('boxall', JSON.stringify(Number(boxall) - 1))
          }
          this.CalPrice(1)
          if (
            this.MyCart.length > 0
            || this.box6.length > 0
            || this.box2b.length > 0
          ) {
            this.CalTime()
          } else {
            clearInterval(this.intervalID)
            this.CountTimeShow = '00 : 00'
            localStorage.setItem(
              'CountTimeShow',
              JSON.stringify(this.CountTimeShow),
            )
          }
        })
    },
    async CalTime() {
      // console.log(this.MyCart.length)
      if (this.MyCart.length > 0) {
        const index = this.MyCart[0].orderby.findIndex(x => x.username === this.userData.username)
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-unused-vars
        var type = 'MyCart'
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var
        var check = this.MyCart[0].orderby[index].order_time
      } else if (this.box6.length > 0) {
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var type = 'box6'
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var check = this.box6[0].updated_at
      } else if (this.box2b.length > 0) {
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var type = 'box2b'
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var check = this.box2b[0].updated_at
      }
      const now = moment()
      // eslint-disable-next-line block-scoped-var
      const duration = moment.duration(now.diff(check))
      const Min = duration.asMinutes()
      // console.log(10 - Min)
      const time = moment().subtract(30, 'minutes')
      // eslint-disable-next-line block-scoped-var
      if (moment(check) < time) {
        // eslint-disable-next-line block-scoped-var
        if (type === 'MyCart') {
          // eslint-disable-next-line no-underscore-dangle
          this.RemovelottoMycart(this.MyCart[0]._id)
          // ลบข้อมูลใน ใน this.MyCart array 0
          this.MyCart.splice(0, 1)
          // set ค่าใหม่ให้ localStorage
          localStorage.setItem('MyCart', JSON.stringify(this.MyCart))
        }
        // eslint-disable-next-line block-scoped-var
        if (type === 'box6') {
          // eslint-disable-next-line no-underscore-dangle
          this.RemoveBoxMycart(this.box6[0]._id)
          // ลบข้อมูลใน ใน this.MyCart array 0
          this.box6.splice(0, 1)
          // set ค่าใหม่ให้ localStorage
          localStorage.setItem('box6', JSON.stringify(this.box6))
        }
        // eslint-disable-next-line block-scoped-var
        if (type === 'box2b') {
          // eslint-disable-next-line no-underscore-dangle
          this.RemoveBoxMycart(this.box2b[0]._id)
          // ลบข้อมูลใน ใน this.MyCart array 0
          this.box2b.splice(0, 1)
          // set ค่าใหม่ให้ localStorage
          localStorage.setItem('box2b', JSON.stringify(this.box2b))
        }

        clearInterval(this.intervalID)
        this.intervalID = null
        this.CountTimeShow = '00 : 00'
        this.CalPrice(1)
      } else {
        this.startTimer(60 * (30 - Min))
      }
    },
    async startTimer(duration) {
      let timer = duration
      // console.log(timer)
      if (timer > 0) {
        let minutes
        let seconds
        clearInterval(this.intervalID)
        this.intervalID = null
        this.intervalID = setInterval(() => {
          minutes = parseInt(timer / 60, 10)
          seconds = parseInt(timer % 60, 10)

          minutes = minutes < 10 ? `0${minutes}` : minutes
          seconds = seconds < 10 ? `0${seconds}` : seconds
          // console.log(seconds)
          this.CountTimeShow = `${minutes} : ${seconds}`
          localStorage.setItem(
            'CountTimeShow',
            JSON.stringify(this.CountTimeShow),
          )
          // console.log(this.CountTimeShow)
          // eslint-disable-next-line no-plusplus
          if (--timer < 0) {
            timer = duration
          }
          if ((minutes === '00' && seconds === '00') || timer < 1) {
            clearInterval(this.intervalID)
            this.intervalID = null
            this.CountTimeShow = '00 : 00'
          }
        }, 1000)
      } else {
        this.CountTimeShow = '00 : 00'
      }
    },
    CalPrice(remove) {
      if (remove) {
        this.sumprice = 0
        this.mypoint = 0
        // eslint-disable-next-line no-unused-vars
        this.MyCart.forEach(item => {
          // console.log(item)
          const index = item.orderby.findIndex(x => x.username === this.userData.username)
          if (index >= 0) {
            if (item.type === 0) {
              this.sumprice += JSON.parse(localStorage.getItem('sellprice')) * item.orderby[index].count
            } else {
              this.sumprice += JSON.parse(localStorage.getItem('luckynum_price')) * item.orderby[index].count
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        this.box6.forEach((item, index) => {
          this.sumprice += item.price_discount
          if (item.count === 10) {
            this.mypoint += 160
          } else if (item.count === 5) {
            this.mypoint += 70
          } else if (item.count === 2) {
            this.mypoint += 25
          } else if (item.count === 1) {
            this.mypoint += 10
          }
        })
        // eslint-disable-next-line no-unused-vars
        this.box2b.forEach((item, index) => {
          this.sumprice += item.price_discount
          if (item.count === 10) {
            this.mypoint += 160
          } else if (item.count === 5) {
            this.mypoint += 70
          } else if (item.count === 2) {
            this.mypoint += 25
          } else if (item.count === 1) {
            this.mypoint += 10
          }
        })
        localStorage.setItem('sumprice', JSON.stringify(this.sumprice))
        this.mypoint += this.MyCart.length * this.getpoints
        // console.log(this.sumprice)
      } else {
        // eslint-disable-next-line no-unused-vars
        this.box6.forEach((item, index) => {
          if (item.count === 10) {
            this.mypoint += 160
          } else if (item.count === 5) {
            this.mypoint += 70
          } else if (item.count === 2) {
            this.mypoint += 25
          } else if (item.count === 1) {
            this.mypoint += 10
          }
        })
        // eslint-disable-next-line no-unused-vars
        this.box2b.forEach((item, index) => {
          if (item.count === 10) {
            this.mypoint += 160
          } else if (item.count === 5) {
            this.mypoint += 70
          } else if (item.count === 2) {
            this.mypoint += 25
          } else if (item.count === 1) {
            this.mypoint += 10
          }
        })
        // this.mypoint += this.MyCart.length * this.getpoints
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.MyCart.length; i++) {
          // eslint-disable-next-line no-unused-expressions, no-loop-func
          this.mypoint += this.MyCart[i].orderby[this.MyCart[i].orderby.findIndex(x => x.username === this.userData.username)].count * this.getpoints
        }
      }
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">Success</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
